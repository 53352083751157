import React from "react"
import Img from "gatsby-image"
import { graphql } from 'gatsby'
// import { FaBullseye } from 'react-icons/fa';

import Layout from "../components/layout"
import SEO from "../components/seo"

const articleHeading = "Mail Us"
const seoTitle = "Mail Us"

const MailUsPage = ({data}) => (
    <Layout>
        <SEO title={seoTitle}/>
        <div className="container">            
            <div className="row article-banner">
                <div className="col">
                    <Img fluid={data.bannerql.childImageSharp.fluid} />
                </div>
            </div>
            <div className="row article-information mail-us">
                <div className="col">
                    <p>Please let us know your concerns, suggestions or anything. We will get back to you as soon as possible.</p>
                </div>
            </div>            
             <div className="row article-heading mail-us">
                <div className="col">
                    <h3><span className="label label-primary">{articleHeading}</span></h3>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <hr />
                </div>
            </div>           
            <div className="row">
                <div className="col">
                <form name="contact" netlify-honeypot="bot-field" data-netlify="true" method="POST" action="https://formspree.io/xjvayzld">
                <input type="hidden" name="bot-field" />
                    <div className="form-group">
                        <label htmlFor="exampleInputSubject1">Subject</label>
                        <input type="text" className="form-control" id="exampleInputSubject1" aria-describedby="subjectHelp" name="Subject"/>
                        <small id="subjectHelp" className="form-text text-muted">Please provide a relevant subject.</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputMessage1">Message</label>
                        <textarea className="form-control" id="exampleInputMessage1" aria-describedby="messageHelp" rows="5" name="Message"/>
                        <small id="messageHelp" className="form-text text-muted">Please let us know your concern.</small>
                    </div>  
                    <div className="form-group">
                        <label htmlFor="exampleInputCopyToMe1">Copy to me</label>
                        <input type="text" className="form-control" id="exampleInputCopyToMe1" aria-describedby="copyToMeHelp" name="Copy To Me"/>
                        <small id="copyToMeHelp" className="form-text text-muted">We will send you a copy.</small>
                    </div>
                    <div className="d-flex justify-content-around">
                        <div><button type="submit" className="btn btn-outline-primary">Submit</button></div>                                     
                        <div><button type="reset" className="btn btn-outline-primary">Reset</button></div>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </Layout>
)

export default MailUsPage

export const query1 = graphql `
    query {
    bannerql: file(relativePath: {eq: "banners/mail-us-1.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1900) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    markupql: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/coming-soon/i"}}) {
        edges {
          node {
            frontmatter {
              heading
            }
            html
          }
        }
      }
}
`